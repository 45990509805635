
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  name: "Edit",
  components: { FileUploader },
  directives: { mask },

  data: () => ({
    rules,
    model: {} as any,
    disableSave: true as boolean,
    fullLoaded: false as boolean,
    errorMessages: {} as { [value: string]: Array<string> },
    selects: {
      directions: [] as Array<any>,
      branches: [] as Array<any>,
      subdivisions: [] as Array<any>
    },
    loading: false as boolean
  }),

  watch: {
    model: {
      deep: true,
      handler() {
        if (this.fullLoaded) {
          this.disableSave = false;
        }
      }
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.subdivisions().getForEdit(Number(to.params.id));
      const subdivisions = await API.subdivisions().getList();
      const branches = await API.branches().getList();
      const directions = await API.directions().getList();

      next(vm => {
        vm.model = item;
        vm.selects.subdivisions = subdivisions.items.filter(
          (element: any) => element.value !== Number(to.params.id)
        );
        vm.selects.branches = branches.items;
        vm.selects.directions = directions;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }

    try {
      const item = await API.banks().getList();

      next(vm => {
        vm.selects.banks = item;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      this.loading = true;
      try {
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };

          await this.$API
            .subdivisions()
            .edit(Number(this.$route.params.id), model);
          await this.$router.push("/subdivisions");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    }
  }
});
